import * as React from "react"
import { graphql } from "gatsby"
import AniLink from "gatsby-plugin-transition-link/AniLink"

import Layout from "../../components/layoutJournal"
import Seo from "../../components/seo"

const JournalIndex = ({ data, location }) => {
  return (
    <Layout location={location}>
      <Seo title="journal" />
      <div className="col-sm-10 font-text">
      </div>
      <div className="col-sm-2">
        <ul className="font-title">
          <li className="list-unstyled my-1"><AniLink className="text-decoration-none link-dark" fade to="/journal/2022">2022</AniLink></li>
        </ul>
      </div>
    </Layout>
  )
}

export default JournalIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
